import { Section } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { SetupCodePreview } from './SetupCodePreview';

interface Props {
  subdomain: string;
}

const PixelCodeInstructionsSection: React.FC<Props> = ({ subdomain }) => {
  const { formatMessage } = useIntl();
  const addScriptCodeSnippet =
    '<!DOCTYPE html>\n' +
    '<html lang="en">\n\n' +
    '    <head>\n' +
    `        <script src='https://${subdomain}/scevent.min.js'></script>\n` +
    '    </head>\n';

  return (
    <Section
      title={formatMessage({
        defaultMessage: 'Configure Your Pixel Code',
      })}
    >
      <p>
        {formatMessage({
          defaultMessage: 'Add this script tag to your website for an optimal data routing setup.',
          description: 'Pixel code instructions',
        })}
      </p>
      <SetupCodePreview codeSnippet={addScriptCodeSnippet} />
    </Section>
  );
};

export default PixelCodeInstructionsSection;
